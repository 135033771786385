<template>
  <div>

    <ul class="uk-pagination mt-5 uk-flex-center" uk-margin>
      <li>

        <a href="#" @click.prevent="changePageNumberDirect(paginate.current_page - 1)"
          v-if="!(Number(paginate.current_page) === 1)">
          <span uk-pagination-previous></span>
        </a>
      </li>
      <li v-for="(link, index) in paginate.links" v-if="!((index === 0) || (index === paginate.links.length - 1))"
        :key="link.label" :class="{ 'uk-active': link.active }">
        <a @click="changePageNumber(link.url)">
          <template v-if="link.active === true"><span>{{ link.label }}</span></template>
          <template v-else>{{ link.label }}</template>
        </a>
      </li>
      <li>
        <a @click="changePageNumberDirect(paginate.current_page + 1)"
          v-if="!(Number(paginate.current_page) === Number(paginate.last_page))">
          <span uk-pagination-next></span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    paginate: {
      default: {}
    },
    perPage: {
      default: 10,
    },
    page: {
      default: 1,
    },
    itemsPerPage: {
      default: function () {
        return [10, 20, 30, 40, 50]
      },
    },
  },
  methods: {
    changePageNumber(url) {
      if (url == null || url === "null") return;
      let page_number = url.split("page=").pop();
      if (Number(this.paginate.current_page) !== Number(page_number)) {
        this.$emit('update:page', page_number);
      }
    },
    changePageNumberDirect(page) {
      let currentPage = null;
      if (page < 1) {
        currentPage = 1;
      } else if (page > this.paginate.last_page) {
        currentPage = this.paginate.last_page;
      } else {
        currentPage = page;
      }
      if (this.paginate.current_page !== currentPage) {
        this.$emit('update:page', currentPage);
      }
    }
  },
  computed: {
    perPageProxy: {
      set(value) {
        this.$emit('update:perPage', value)
      },
      get() {
        return this.perPage;
      },
    }
  },
}
</script>

<style scoped>
.cursor-initial {
  cursor: initial !important;
}

.pagination_button {
  height: 50px;
  width: 50px;
  border-radius: 50%;

}</style>
